<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 14 14.004">
    <path
      :style="iconStyle"
      d="M15.344,9.5A1.8,1.8,0,0,1,16.5,7.819a7.141,7.141,0,0,0-.864-2.082,1.826,1.826,0,0,1-.733.157,1.8,1.8,0,0,1-1.644-2.53A7.119,7.119,0,0,0,11.181,2.5a1.8,1.8,0,0,1-3.361,0,7.141,7.141,0,0,0-2.082.864,1.8,1.8,0,0,1-1.644,2.53,1.766,1.766,0,0,1-.733-.157A7.3,7.3,0,0,0,2.5,7.823a1.8,1.8,0,0,1,0,3.361,7.141,7.141,0,0,0,.864,2.082A1.8,1.8,0,0,1,5.741,15.64a7.183,7.183,0,0,0,2.082.864,1.8,1.8,0,0,1,3.354,0,7.141,7.141,0,0,0,2.082-.864,1.8,1.8,0,0,1,2.373-2.373,7.183,7.183,0,0,0,.864-2.082A1.81,1.81,0,0,1,15.344,9.5ZM9.533,12.413A2.917,2.917,0,1,1,12.449,9.5,2.916,2.916,0,0,1,9.533,12.413Z"
      transform="translate(-2.5 -2.5)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '13.59',
    },
    width: {
      type: String,
      default: '13.169',
    },
    color: {
      type: String,
      default: 'primary-green',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
